import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import AddCafeModal from "../components/Cafes/modal/AddCafeModal";
import DeleteConfirmationModal from "../components/DeleteModal";
import axios from "axios";

const CafeSaleMainPage = () => {
  const token = localStorage.getItem("authToken");
  const [cafes, setCafes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editCafe, setEditCafe] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State to control the delete confirmation modal
  const [cafeToDelete, setCafeToDelete] = useState(null); 
  const navigate = useNavigate();

  // Fetch cafes on component mount
  useEffect(() => {
    fetchCafes();
  }, [token]);

  const fetchCafes = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/cafe`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCafes(response.data.cafe);
    } catch (error) {
      console.error(
        "Error fetching cafes:",
        error.response?.data || error.message
      );
    } finally {
      setLoading(false);
    }
  };
  const handleModalSubmit = async (cafeData) => {
    try {
      if (editCafe) {
        const response = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/api/cafe/${editCafe._id}`,
          cafeData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const updatedCafe = response.data.cafe;
        if (!updatedCafe) {
          throw new Error("Invalid response structure from server");
        }

        setCafes((prevCafes) =>
          prevCafes.map((cafe) =>
            cafe._id === updatedCafe._id ? updatedCafe : cafe
          )
        );
      } else {
        // Add a new cafe
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/cafe`,
          cafeData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { message } = response.data;

        if (message !== "Cafe created successfully") {
          throw new Error("Failed to create cafe");
        }
        // Fetch the updated list of cafes after adding a new one (since backend doesn't return the new cafe)
        const cafesResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/cafe`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (cafesResponse.data.cafe) {
          setCafes(cafesResponse.data.cafe);
        }
      }
      setIsModalOpen(false);
    } catch (error) {
      console.error(
        "Failed to save cafe:",
        error.response?.data || error.message
      );
      alert("An error occurred while saving the cafe. Please try again.");
    }
  };

  const handleDeleteCafe = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/cafe/${cafeToDelete._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setCafes((prevCafes) =>
        prevCafes.filter((cafe) => cafe._id !== cafeToDelete._id)
      );
      setIsDeleteModalOpen(false); // Close the modal after deletion
    } catch (error) {
      console.error("Failed to delete cafe:", error.response?.data || error.message);
      alert("An error occurred while deleting the cafe. Please try again.");
    }
  };

  const handleCardClick = (cafe) => {
    navigate(`/cafe/${cafe.cafeName}`, { state: cafe });
  };

  const openAddModal = () => {
    setEditCafe(null);
    setIsModalOpen(true);
  };

  const openEditModal = (cafe) => {
    setEditCafe(cafe);
    setIsModalOpen(true);
  };

  const openDeleteModal = (cafe) => {
    setCafeToDelete(cafe); // Set the cafe to be deleted
    setIsDeleteModalOpen(true); // Open the delete confirmation modal
  };
  return (
    <div className="p-8 bg-gray-100 min-h-screen flex flex-col items-center">
      <div className="w-full flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-800 text-left pl-10">
          Cafe Sale Management
        </h1>
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-700 focus:outline-none"
          onClick={openAddModal}
        >
          + Add New Cafe
        </button>
      </div>

      {loading ? (
        <p>Loading cafes...</p>
      ) : cafes.length === 0 ? (
        <p>No cafes available.</p>
      ) : (
        <div className="flex flex-wrap justify-center gap-8 mt-8">
          {cafes.map((cafe) => (
            <div
              key={cafe.id} // Use unique id from the API
              className="bg-white rounded-lg shadow-xl p-8 w-96 text-center cursor-pointer hover:scale-110 transform transition duration-300"
              onClick={() => handleCardClick(cafe)}
            >
              <h3 className="text-2xl font-semibold text-gray-800">
                {cafe.cafeName}
              </h3>
              <p className="text-gray-600 mt-4">{cafe.address}</p>
              <p className="text-gray-500 mt-4">{cafe.cafeInfo}</p>
              <div className="flex justify-center items-center mt-4">
  {Array.from({ length: 5 }, (_, i) => (
    <i
      key={i}
      className={`text-yellow-500 ${
        cafe.rating >= i + 1
          ? "fas fa-star"
          : cafe.rating > i
          ? "fas fa-star-half-alt"
          : "far fa-star"
      }`}
    ></i>
  ))}
  <span className="text-gray-600 ml-2">({cafe.rating})</span>
              </div>
              <div className="flex justify-center gap-4 mt-6">
                <button
                  className="text-yellow-500 hover:text-yellow-700 focus:outline-none"
                  onClick={(e) => {
                    e.stopPropagation();
                    openEditModal(cafe);
                  }}
                >
                  <FaEdit size={20} />
                </button>
                <button
                  className="text-red-500 hover:text-red-700 focus:outline-none"
                  onClick={(e) => {
                    e.stopPropagation();
                    openDeleteModal(cafe);
                  }}
                >
                  <FaTrashAlt size={20} />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      <AddCafeModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleModalSubmit}
        initialData={editCafe}
      />

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        isVisible={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteCafe}
        message={`Are you sure you want to delete "${cafeToDelete?.cafeName}"?`}
      />
    </div>
  );
};

export default CafeSaleMainPage;
