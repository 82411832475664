import React, { useState } from "react";
import axios from "axios";

const UploadTransactionCSV = ({ bankName }) => {
    console.log("bank name", bankName);
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState("");

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!file) {
            setMessage("Please select a CSV file to upload.");
            return;
        }

        if (!bankName) {
            setMessage("Bank name is required.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/api/transaction/uploadTransaction/${bankName}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            console.log("Response data:", response);
            setMessage("File uploaded successfully!");
            
            // Reset file input
            setFile(null);
            window.location.reload();
        } catch (error) {
            console.error("Failed to upload CSV:", error);
            setMessage(error.response?.data?.message || "An error occurred.");
        }
    };

    return (
        <div>
            <h2>Upload Transaction CSV</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="file"
                    name="file"
                    accept=".csv"
                    onChange={handleFileChange}
                    value={file ? undefined : ""}
                />
                <button type="submit">Upload</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default UploadTransactionCSV;
