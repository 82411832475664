import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaShoppingCart, FaCashRegister } from 'react-icons/fa'; // Import icons

const CafeDetailPage = () => {
    const location = useLocation();
    const cafe = location.state; // Retrieve the passed cafe data
    const navigate = useNavigate();

    const handleSale = () => {
        navigate(`/cafe/${cafe.cafeName}/sales`, { state: cafe });
    };
    
    const handlePurchase = () => {
        navigate(`/cafe/${cafe.cafeName}/purchases`, { state: cafe });
    };

    return (
        <div className="px-8 pt-20 bg-gradient-to-br from-gray-100 via-white to-gray-300 min-h-screen flex flex-col items-center">
            {/* Heading */}
            <h1 className="text-4xl font-bold text-gray-800 mb-12 shadow-md bg-white px-8 py-4 rounded-lg">
                {cafe.cafeName} Details
            </h1>

            <div className="flex flex-wrap justify-center gap-6">
                {/* Sales Card */}
                <div
                    className="bg-white rounded-xl shadow-2xl p-8 w-96 text-center cursor-pointer hover:scale-105 transform transition duration-300 hover:shadow-xl"
                    onClick={handleSale}
                >
                    <div className="flex justify-center items-center text-blue-500 mb-4">
                        <FaCashRegister size={50} />
                    </div>
                    <h3 className="text-2xl font-semibold text-gray-800 mb-2">Sale of {cafe.cafeName}</h3>
                    <p className="text-gray-600">{cafe.address}</p>
                    <p className="text-gray-500 mt-2">{cafe.info}</p>
                </div>

                {/* Purchases Card */}
                <div
                    className="bg-white rounded-xl shadow-2xl p-8 w-96 text-center cursor-pointer hover:scale-105 transform transition duration-300 hover:shadow-xl"
                    onClick={handlePurchase}
                >
                    <div className="flex justify-center items-center text-green-500 mb-4">
                        <FaShoppingCart size={50} />
                    </div>
                    <h3 className="text-2xl font-semibold text-gray-800 mb-2">Purchase of {cafe.cafeName}</h3>
                    <p className="text-gray-600">{cafe.address}</p>
                    <p className="text-gray-500 mt-2">{cafe.info}</p>
                </div>
            </div>
        </div>
    );
};

export default CafeDetailPage;
