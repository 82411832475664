import React, { useState, useEffect } from 'react';

const AddCafeModal = ({ isOpen, onClose, onSubmit, initialData }) => {
    const [cafeData, setCafeData] = useState({
        cafeName: '',
        address: '',
        cafeInfo: '',
        rating: 0,
    });

    useEffect(() => {
        // Update the form data when `initialData` changes
        if (initialData) {
            setCafeData(initialData);
        } else {
            // Reset the form if there's no `initialData`
            setCafeData({
                cafeName: '',
                address: '',
                cafeInfo: '',
                rating: 0,
            });
        }
    }, [initialData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCafeData({ ...cafeData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(cafeData); // Pass the data back to the parent component
        onClose(); // Close the modal
    };

    if (!isOpen) return null; // Don't render the modal if not open

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">
                    {initialData ? 'Edit Cafe' : 'Add New Cafe'}
                </h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="name" className="block text-gray-700">Cafe Name</label>
                        <input
                            type="text"
                            id="name"
                            name="cafeName"
                            value={cafeData.cafeName}
                            onChange={handleInputChange}
                            className="w-full p-3 border border-gray-300 rounded-md"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="address" className="block text-gray-700">Address</label>
                        <input
                            type="text"
                            id="address"
                            name="address"
                            value={cafeData.address}
                            onChange={handleInputChange}
                            className="w-full p-3 border border-gray-300 rounded-md"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="info" className="block text-gray-700">Cafe Information</label>
                        <textarea
                            id="info"
                            name="cafeInfo"
                            value={cafeData.cafeInfo}
                            onChange={handleInputChange}
                            className="w-full p-3 border border-gray-300 rounded-md"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="rating" className="block text-gray-700">Rating</label>
                        <input
                            type="number"
                            id="rating"
                            name="rating"
                            value={cafeData.rating}
                            onChange={handleInputChange}
                            className="w-full p-3 border border-gray-300 rounded-md"
                            min="0"
                            max="5"
                            step="0.1"
                            required
                        />
                    </div>
                    <div className="flex justify-end mt-6">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-300 text-gray-700 py-2 px-4 rounded-full mr-2"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-700 focus:outline-none"
                        >
                            {initialData ? 'Save Changes' : 'Add Cafe'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddCafeModal;