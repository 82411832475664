import React, { useState } from "react";
import axios from "axios";

const UploadCSV = ({ cafeName }) => {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState("");

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!file) {
            setMessage("Please select a CSV file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file); // Ensure this matches the field name in Multer

        try {
            const response = await axios.post(
                `http://localhost:8000/api/sales/upload/${cafeName}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            console.log("response data ",response.data);
            setMessage(response.data.message);
            setFile(null);
            window.location.reload();
            
        } catch (error) {
            console.error("Failed to upload CSV:", error);
            setMessage(error.response?.data?.message || "An error occurred.");
        }
    };

    return (
        <div>
            <h2>Upload Sales CSV</h2>
            <form onSubmit={handleSubmit}>
                <input type="file" name="file" accept=".csv" onChange={handleFileChange} />
                <button type="submit">Upload</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default UploadCSV;