
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaEdit, FaSearch } from "react-icons/fa";
import TransactionModal from "../components/TransactionModal";
import axios from "axios";
import * as XLSX from "xlsx";
import { GoDownload } from "react-icons/go";
import UploadTransactionCSV from "../components/UploadedTransactionCSV";

const BankDetails = () => {
  const { bankName } = useParams();
  const navigate = useNavigate();
  const [isUploadVisible, setIsUploadVisible] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const pageSize = 12; // Number of transactions per page

  const token = localStorage.getItem("authToken"); // Retrieve token from localStorage
 

  // Fetch transactions from backend
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/transaction/${bankName}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTransactions(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized: Redirecting to login...");
          localStorage.removeItem("authToken"); // Clear invalid token
          navigate("/login"); // Redirect to login page
        } else {
          console.error("Error fetching transactions:", error);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchTransactions();
  }, [bankName, navigate, token, modalOpen, transactions]);

  const handleAddTransaction = () => {
    setEditData(null);
    setModalOpen(true);
  };

  const handleEditTransaction = (transaction) => {
    setEditData(transaction);
    setModalOpen(true);
  };

  const handleModalSubmit = async (data) => {
    try {
      if (editData) {
        // Update existing transaction
        await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/api/transaction/${bankName}/${editData._id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTransactions((prev) =>
          prev.map((item) =>
            item.id === editData.id ? { ...item, ...data } : item
          )
        );
      } else {
        // Add new transaction
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/transaction/${bankName}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTransactions((prev) => [...prev, response.data]);
      }
      setModalOpen(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized: Redirecting to login...");
        localStorage.removeItem("authToken");
        navigate("/login");
      } else {
        console.error("Error saving transaction:", error);
      }
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const filteredTransactions = transactions.filter((transaction) =>
    transaction.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedTransactions = filteredTransactions.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const totalPages = Math.ceil(filteredTransactions.length / pageSize);

  if (loading) {
    return <p>Loading transactions...</p>;
  }

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredTransactions);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Transaction");
    XLSX.writeFile(wb, `${bankName}_Transaction.xlsx`);
  };

  // Pagination logic
  const getPaginationRange = () => {
    const maxPagesToShow = 10;
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    // Adjust startPage if endPage is less than maxPagesToShow
    if (endPage - startPage < maxPagesToShow - 1) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = getPaginationRange();
  

  return (
    <div className="p-8">
      <div className="flex flex-col lg:flex-row justify-between items-center mb-6">
        <div>
          <h1 className="text-3xl font-bold text-gray-800">{bankName}</h1>
          <p className="text-gray-600">
            Manage all transactions for this account
          </p>
        </div>
        <div className="flex flex-col lg:flex-row items-center gap-4 mt-4 lg:mt-0">
          <div className="relative">
            <input
              type="text"
              placeholder="Search by description"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 pr-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
          <div className="flex flex-wrap items-center gap-4">
            <button
              className="flex items-center justify-center gap-2 px-4 py-2 bg-blue-100 rounded hover:bg-blue-200 w-full sm:w-auto"
              onClick={exportToExcel}
            >
              <GoDownload className="text-xl text-blue-500" />
              Download
            </button>
            <button
              className="bg-green-600 text-white px-4 py-2 rounded-md flex items-center gap-2 w-full sm:w-auto"
              onClick={() => setIsUploadVisible(!isUploadVisible)}
            >
              Upload CSV
            </button>
            {isUploadVisible && <UploadTransactionCSV bankName={bankName} />}
          </div>
          <button
            onClick={handleAddTransaction}
            className="bg-blue-600 text-white px-4 py-2 rounded shadow hover:bg-blue-700"
          >
            + Add Transaction
          </button>
        </div>
      </div>

      <div className="overflow-x-auto mx-auto">
        <table className="min-w-full bg-white border border-gray-200 text-center">
          <thead>
            <tr className="bg-gray-100">
              <th className="py-2 px-4 border-b">S.No</th>
              <th className="py-2 px-4 border-b">Date</th>
              <th className="py-2 px-4 border-b">Description</th>
              <th className="py-2 px-4 border-b">Particular</th>
              <th className="py-2 px-4 border-b">Credit</th>
              <th className="py-2 px-4 border-b">Debit</th>
              <th className="py-2 px-4 border-b">Balance</th>
              <th className="py-2 px-4 border-b">Action</th>
            </tr>
          </thead>
          <tbody>
            {paginatedTransactions.map((transaction, index) => (
              <tr
                key={transaction.id}
                className="hover:bg-gray-100 transition duration-200"
              >
                <td className="py-2 px-4 border-b">
                  {(currentPage - 1) * pageSize + index + 1}
                </td>
                <td className="border-b px-6 py-4">
                  {new Date(transaction.date).toLocaleDateString("en-GB")}
                </td>
                <td className="py-2 px-4 border-b">{transaction.description }</td>
                <td className="py-2 px-4 border-b">{transaction.particular}</td>
                <td className="py-2 px-4 border-b text-green-600">
                  {transaction.credit}
                </td>
                <td className="py-2 px-4 border-b text-red-600">
                  {transaction.debit}
                </td>
                <td className="py-2 px-4 border-b">{transaction.balance}</td>
                <td className="py-2 px-4 border-b">
                  <button
                    className="text-blue-500 hover:text-blue-700"
                    onClick={() => handleEditTransaction(transaction)}
                  >
                    <FaEdit />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
          className={`px-4 py-2 mx-1 border rounded ${currentPage === 1
            ? "bg-gray-200 text-gray-400 cursor-not-allowed"
            : "bg-white text-gray-700 hover:bg-gray-100"
            }`}
        >
          Previous
        </button>
        {startPage > 1 && (
          <>
            <button
              onClick={() => handlePageChange(1)}
              className="px-4 py-2 mx-1 border rounded bg-white text-gray-700 hover:bg-gray-100"
            >
              1
            </button>
            {startPage > 2 && <span className="mx-1">...</span>}
          </>
        )}
        {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
          <button
            key={startPage + index}
            onClick={() => handlePageChange(startPage + index)}
            className={`px-4 py-2 mx-1 border rounded ${currentPage === startPage + index
              ? "bg-blue-600 text-white"
              : "bg-white text-gray-700 hover:bg-gray-100"
              }`}
          >
            {startPage + index}
          </button>
        ))}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span className="mx-1">...</span>}
            <button
              onClick={() => handlePageChange(totalPages)}
              className="px-4 py-2 mx-1 border rounded bg-white text-gray-700 hover:bg-gray-100"
            >
              {totalPages}
            </button>
          </>
        )}
        <button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
          className={`px-4 py-2 mx-1 border rounded ${currentPage === totalPages
            ? "bg-gray-200 text-gray-400 cursor-not-allowed"
            : "bg-white text-gray-700 hover:bg-gray-100"
            }`}
        >
          Next
        </button>
      </div>

      <TransactionModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
        initialData={editData}
      />
    </div>
  );
};

export default BankDetails;
