import React, { useEffect, useState } from 'react';

const AddPurchaseModal = ({ onClose, onSave, editData }) => {
    const [formData, setFormData] = useState({
        date: '',
        billNo: '',
        price: '',
        item: '',
        quantity: '',
        remarks: '',
        MRP: '',
    });

    useEffect(() => {
        if (editData) {
            setFormData({
                date: editData.date,
                billNo: editData.billNo,
                item: editData.item,
                quantity: editData.quantity,
                MRP: editData.MRP,
                price: editData.price,
                remarks: editData.remarks,
            });
        }
    }, [editData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formData); // Call the onSave prop with formData
        setFormData({
            date: '',
            billNo: '',
            item: '',
            quantity: '',
            MRP: '',
            remarks: '',
            price: '',
        });
        onClose();
    };

    return (
        <div className="flex items-center justify-center h-1/1 bg-gray-100">
            <div className="rounded-lg p-6 w-96">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-2xl font-bold text-gray-800">{editData ? "Edit Purchase Data" : "Add Purchase Data"}</h1>
                </div>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Date</label>
                        <input
                            type="date"
                            name="date"
                            value={formData.date}
                            onChange={handleChange}
                            className="w-full mt-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Date"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Bill No</label>
                        <input
                            type="text"
                            name="billNo"
                            value={formData.billNo}
                            onChange={handleChange}
                            className="w-full mt-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Bill No"
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Price</label>
                            <input
                                type="number"
                                name="price"
                                value={formData.price}
                                onChange={handleChange}
                                className="w-full mt-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Price"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">MRP</label>
                            <input
                                type="number"
                                name="MRP"
                                value={formData.MRP}
                                onChange={handleChange}
                                className="w-full mt-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="MRP"
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Item</label>
                            <input
                                type="text"
                                name="item"
                                value={formData.item}
                                onChange={handleChange}
                                className="w-full mt-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Item"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Quantity</label>
                            <input
                                type="number"
                                name="quantity"
                                value={formData.quantity}
                                onChange={handleChange}
                                className="w-full mt-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Quantity"
                                required
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Remarks</label>
                        <input
                            type="text"
                            name="remarks"
                            value={formData.remarks}
                            onChange={handleChange}
                            className="w-full mt-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Remarks"
                        />
                    </div>
                    <div className="flex justify-between space-x-4 mt-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="flex-1 bg-red-500 text-white py-2 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="flex-1 bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddPurchaseModal;
