import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiEdit, FiTrash } from "react-icons/fi";
import AddAccountModal from "../components/AddAccountModal";
import DeleteConfirmationModal from "../components/DeleteModal";
import axios from "axios";

const AccountStatement = () => {
  const [accounts, setAccounts] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editingAccount, setEditingAccount] = useState(null);
  const [accountToDelete, setAccountToDelete] = useState(null);
  const navigate = useNavigate();

  const token = localStorage.getItem("authToken");
 
  const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/bank`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  useEffect(() => {
    const fetchBankDetails = async () => {
      try {
        const response = await axiosInstance.get("/");
        setAccounts(response.data);
      } catch (error) {
        console.error("Error fetching bank details:", error);
        if (error.response?.status === 401) {
          alert("Unauthorized access. Please login again.");
          navigate("/login");
        }
      }
    };
    fetchBankDetails();
  }, [axiosInstance, navigate]);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const toggleDeleteModal = () => {
    setDeleteModalVisible(!isDeleteModalVisible);
  };

  const addAccount = async (newAccount) => {
    try {
      if (editingAccount) {
        const response = await axiosInstance.put(
          `/${editingAccount._id}`,
          newAccount
        );
        setAccounts((prevAccounts) =>
          prevAccounts.map((account) =>
            account._id === editingAccount._id
              ? { ...response.data.data }
              : account
          )
        );
      } else {
        const response = await axiosInstance.post("/", newAccount);
        setAccounts((prevAccounts) => [...prevAccounts, response.data]);
      }
    } catch (error) {
      console.error("Failed to add or update account:", error);
      alert("Failed to add or update account. Please try again.");
    }
    setEditingAccount(null);
    toggleModal();
  };

  const editAccount = (account) => {
    setEditingAccount(account);
    toggleModal();
  };

  const confirmDeleteAccount = async () => {
    try {
      const accountId = accountToDelete._id;
      const response = await axiosInstance.delete(`/${accountId}`);
      if (response.status === 200) {
        setAccounts((prevAccounts) =>
          prevAccounts.filter((account) => account._id !== accountId)
        );
        setAccountToDelete(null);
        toggleDeleteModal();
      }
    } catch (error) {
      console.error("Failed to delete account:", error);
      alert("Failed to delete account. Please try again.");
    }
  };

  const requestDeleteAccount = (account) => {
    setAccountToDelete(account);
    toggleDeleteModal();
  };

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Account Management</h1>
        <button
          onClick={() => {
            setEditingAccount(null);
            toggleModal();
          }}
          className="bg-blue-600 text-white px-4 py-2 rounded shadow hover:bg-blue-700"
        >
          + Add Account
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
        {accounts.map((account, index) => (
          <div
            key={index}
            className="bg-white p-4 shadow-md rounded-lg border border-gray-200 relative"
          >
            <h2 className="text-xl font-semibold text-gray-800">{account.bankName}</h2>
            <p className="text-gray-600 mt-1">
              Account Holder Name: {account.accountHolderName}
            </p>
            <p className="text-gray-600 mt-1">IFSC Code: {account.ifscCode}</p>
            <p className="text-gray-600 mt-2">Account No: {account.accountNumber}</p>
            <p className="text-gray-600 mt-1">Current Balance: {account.balance}</p>

            <div className="absolute top-4 right-4 flex space-x-3">
              <FiEdit
                className="text-blue-500 cursor-pointer hover:text-blue-700"
                size={20}
                onClick={() => editAccount(account)}
              />
              <FiTrash
                className="text-red-500 cursor-pointer hover:text-red-700"
                size={20}
                onClick={() => requestDeleteAccount(account)}
              />
            </div>

            <button
              className="mt-4 bg-gray-100 text-blue-600 hover:bg-gray-200 px-3 py-1 rounded"
              onClick={() => navigate(`/account-statement/${account.bankName}`)}
            >
              View Details
            </button>
          </div>
        ))}
      </div>

      <AddAccountModal
        isVisible={isModalVisible}
        onClose={toggleModal}
        onAddAccount={addAccount}
        editingAccount={editingAccount}
      />

      <DeleteConfirmationModal
        isVisible={isDeleteModalVisible}
        onClose={toggleDeleteModal}
        onConfirm={confirmDeleteAccount}
        message={`Are you sure to delete the account of ${accountToDelete?.accountHolderName}?`}
      />
    </div>
  );
};

export default AccountStatement;
