import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import { GoDownload } from "react-icons/go";
import AddPurchaseModal from "../components/Cafes/modal/AddPurchaseModal"; // Create this modal for purchases
import * as XLSX from "xlsx";
import { FiSearch } from "react-icons/fi";
import axios from "axios";

const CafeSales = () => {
  const location = useLocation();
  const cafeId = location.state?.id;
  const cafeName = location.state?.cafeName;
  const address = location.state?.address;
  const token = localStorage.getItem("authToken");

  const navigate = useNavigate();
  const [editPurchaseData, setEditPurchaseData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [purchaseData, setPurchaseData] = useState([]);
  const [filteredPurchases, setFilteredPurchases] = useState(purchaseData);
  const [itemFilter, setItemFilter] = useState("");
  const [filters, setFilters] = useState({
    month: "",
    date: "",
  });
  const totalAmount = filteredPurchases.reduce((sum, row) => {
    const amountValue =
      typeof row.amount === "string" ? row.amount : String(row.amount || "0");
    return sum + parseInt(amountValue.replace("₹", ""), 10);
  }, 0);
  const goBackPage = (cafeName) => {
    navigate(`/cafe/${cafeName}`, {
      state: {
        cafeName: cafeName,
        address: address,
      },
    });
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setEditPurchaseData(null); // Reset edit data when closing modal
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    applyFilters({ ...filters, [name]: value });
  };

  const handleItemFilterChange = (e) => {
    const value = e.target.value;
    setItemFilter(value);
    applyFilters({ ...filters, item: value });
  };

  const resetFilters = () => {
    setFilters({ month: "", date: "" });
    setItemFilter("");
    setFilteredPurchases(purchaseData);
  };

  const applyFilters = (updatedFilters) => {
    let filtered = purchaseData;

    // Filter by month
    if (updatedFilters.month) {
      filtered = filtered.filter((purchase) => {
        const purchaseMonth = new Date(purchase.date).toISOString().slice(0, 7); // Format: "YYYY-MM"
        return purchaseMonth === updatedFilters.month;
      });
    }

    // Filter by specific date
    if (updatedFilters.date) {
      filtered = filtered.filter((purchase) => {
        const purchaseDate = new Date(purchase.date)
          .toISOString()
          .split("T")[0]; // Format: "YYYY-MM-DD"
        return purchaseDate === updatedFilters.date;
      });
    }

    // Filter by item
    if (updatedFilters.item) {
      filtered = filtered.filter((purchase) =>
        purchase.item?.toLowerCase().includes(updatedFilters.item.toLowerCase())
      );
    }
    setFilteredPurchases(filtered);
  };

  const handleEdit = (purchase) => {
    setEditPurchaseData(purchase); // Set the selected purchase for editing
    setIsModalOpen(true); // Open the modal
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredPurchases);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "CafePurchase");
    XLSX.writeFile(wb, `${cafeName}_CafePurchase.xlsx`);
  };

  // Fetch purchase data
  const fetchPurchaseData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/purchases/${cafeName}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setPurchaseData(response.data.purcheshDetails);
      setFilteredPurchases(response.data.purcheshDetails);
    } catch (error) {
      console.error(
        "Failed to fetch purchase data:",
        error.response?.data || error.message
      );
    }
  };

  useEffect(() => {
    if (cafeName && token) {
      fetchPurchaseData();
    }
  }, [cafeName, token]);

  // Handle submit for adding and editing purchases
  const handleSubmit = async (purchaseData) => {

    try {
      if (editPurchaseData) {
        // Handle Update
        const response = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/api/purchases/${cafeName}/${editPurchaseData._id}`,
          purchaseData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const updatedPurchaseData = response.data.updatedPurchesh;

        // Update both purchaseData and filteredPurchases
        setPurchaseData((prevData) =>
          prevData.map((data) =>
            data._id === updatedPurchaseData._id ? updatedPurchaseData : data
          )
        );
        setFilteredPurchases((prevFiltered) =>
          prevFiltered.map((data) =>
            data._id === updatedPurchaseData._id ? updatedPurchaseData : data
          )
        );
      } else {
        // Handle Create
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/purchases/${cafeName}`,
          purchaseData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const newPurchaseData = response.data.newPurchesh; 

        // Add to both purchaseData and filteredPurchases
        setPurchaseData((prevData) => [...prevData, newPurchaseData]);
        setFilteredPurchases((prevFiltered) => [
          ...prevFiltered,
          newPurchaseData,
        ]);
      }

      // Close the modal and reset edit state
      setIsModalOpen(false);
      setEditPurchaseData(null);
    } catch (error) {
      console.error(
        "Failed to submit purchase data:",
        error.response?.data || error.message
      );
      alert(
        "Failed to post data: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-200 p-6">
      <div className="flex flex-wrap items-center justify-between gap-4">
        {/* Back Button */}
        <button
          className="flex items-center text-blue-700 font-medium text-lg hover:text-blue-900 transition"
          onClick={() => goBackPage(cafeName)}
        >
          <IoIosArrowBack className="text-2xl mr-2" />
          Back
        </button>

        {/* Filters and Actions */}
        <div className="flex flex-wrap items-center justify-center gap-5 w-full lg:w-auto ml-5">
          {/* Month Filter */}
          <div className="flex flex-col sm:flex-row items-center gap-2 w-full sm:w-auto">
            <label className="text-sm font-medium text-gray-600">Month</label>
            <input
              type="month"
              name="month"
              className="border rounded px-3 py-2 w-full sm:w-auto"
              onChange={handleFilterChange}
              value={filters.month}
            />
          </div>

          {/* Date Filter */}
          <div className="flex flex-col sm:flex-row items-center gap-2 w-full sm:w-auto">
            <label className="text-sm font-medium text-gray-600">Date</label>
            <input
              type="date"
              name="date"
              className="border rounded px-3 py-2 w-full sm:w-auto"
              onChange={handleFilterChange}
              value={filters.date}
            />
          </div>

          {/* Item Filter */}
          <div className="relative flex items-center gap-2 w-full sm:w-auto">
            <input
              type="text"
              name="item"
              className="w-full sm:w-64 px-4 py-2 pl-10 border border-gray-300 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
              placeholder="Filter by item"
              onChange={handleItemFilterChange}
              value={itemFilter}
            />
            <FiSearch className="absolute left-3 top-2/4 transform -translate-y-1/2 text-gray-500" />
          </div>

          {/* Reset Filters Button */}
          <button
            className="w-full sm:w-auto bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
            onClick={resetFilters}
          >
            Reset
          </button>

          {/* Export to Excel Button and Add Purchase Button */}
          <div className="flex flex-wrap gap-4 w-full sm:w-auto">
            <button
              className="flex items-center justify-center gap-2 px-4 py-2 bg-blue-100 rounded hover:bg-blue-200 w-full sm:w-auto"
              onClick={exportToExcel}
            >
              <GoDownload style={{ fontSize: "2rem", color: "blue" }} />
              <span>Download</span>
            </button>
            <button
              className="flex items-center justify-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition w-full sm:w-auto"
              onClick={toggleModal}
            >
              <IoMdAdd className="text-xl" />
              <span>Add Purchase</span>
            </button>
          </div>
        </div>

        {/* Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <AddPurchaseModal
              onClose={toggleModal}
              onSave={handleSubmit}
              editData={editPurchaseData}
            />
          </div>
        )}
      </div>

      <div className="flex flex-col items-center mt-5">
        <h1 className="text-4xl font-bold text-gray-800 mb-10">{cafeName}</h1>
        <div className="overflow-x-auto w-full max-w-6xl">
          <table className="table-auto border-collapse border border-gray-300 w-full text-center bg-white shadow-lg rounded-lg">
            <thead className="bg-blue-500 text-white text-lg">
              <tr>
                <th className="border border-gray-200 px-6 py-4">S. No</th>
                <th className="border border-gray-200 px-6 py-4">Date</th>
                <th className="border border-gray-200 px-6 py-4">Bill No</th>
                <th className="border border-gray-200 px-6 py-4">Item</th>
                <th className="border border-gray-200 px-6 py-4">Quantity</th>
                <th className="border border-gray-200 px-6 py-4">Price</th>
                <th className="border border-gray-200 px-6 py-4">MRP</th>
                <th className="border border-gray-200 px-6 py-4">Amount</th>
                <th className="border border-gray-200 px-6 py-4">Remarks</th>
                <th className="border border-gray-200 px-6 py-4">Action</th> 
              </tr>
            </thead>
            <tbody>
              {filteredPurchases.map((data, index) => (
                <tr
                  key={data.id}
                  className={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-blue-50"
                  } hover:bg-blue-100 transition-colors duration-300`}
                >
                  <td className="border border-gray-300 px-6 py-4 font-medium">
                    {index + 1}
                  </td>
                  <td className="border border-gray-300 px-6 py-4">
                    {new Date(data.date).toLocaleDateString("en-GB")}
                  </td>
                  <td className="border border-gray-300 px-6 py-4">
                    {data.billNo}
                  </td>
                  <td className="border border-gray-300 px-6 py-4">
                    {data.item}
                  </td>
                  <td className="border border-gray-300 px-6 py-4">
                    {data.quantity}
                  </td>
                  <td className="border border-gray-300 px-6 py-4">
                    {data.price}
                  </td>

                  <td className="border border-gray-300 px-6 py-4">
                    {data.MRP}
                  </td>
                  
                  <td className="border border-gray-300 px-6 py-4 font-semibold text-blue-500">
                    {data.amount}
                  </td>
                  <td className="border border-gray-300 px-6 py-4">
                    {data.remarks}
                  </td>
                  <td className="border px-6 py-4">
                    <FaRegEdit
                      className="text-blue-500 text-xl cursor-pointer"
                      onClick={() => handleEdit(data)}
                    />
                  </td>
                </tr>
              ))}
              <tr className="bg-yellow-100">
                <td
                  className="border border-gray-300 px-6 py-4 font-bold"
                  colSpan="7"
                >
                  Total
                </td>
                <td className="border border-gray-300 px-6 py-4 font-bold text-green-800">
                  ₹{totalAmount}
                </td>
                <td className="border border-gray-300 px-6 py-4"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CafeSales;