import React from 'react';
import { FaChartPie, FaUserTie, FaMoneyCheckAlt, FaCalendarAlt } from 'react-icons/fa'; // React Icons
import { format } from 'date-fns'; // Import date-fns

const Dashboard = () => {
  const currentDate = format(new Date(), 'MMMM do, yyyy');

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto px-4 py-8">
        <div className="text-center mb-8">
         <h1 className="text-4xl font-bold text-gray-800 mb-2">KB Account Software</h1>
          <h1 className="text-2xl font-bold text-gray-800 mb-2">Dashboard</h1>
          <p className="text-gray-600">Welcome to the Account Software Dashboard. Monitor your key metrics and manage your business efficiently.</p>
          <p className="text-gray-600 mt-2">Date: {currentDate}</p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="bg-white p-6 rounded-lg shadow-md flex items-center space-x-4">
            <FaChartPie className="text-blue-500 text-4xl" />
            <div>
              <h3 className="text-lg font-bold text-gray-800">Sales Overview</h3>
              <p className="text-gray-600">View your sales data for the day.</p>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md flex items-center space-x-4">
            <FaUserTie className="text-green-500 text-4xl" />
            <div>
              <h3 className="text-lg font-bold text-gray-800">Employee Performance</h3>
              <p className="text-gray-600">Track employee performance metrics.</p>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md flex items-center space-x-4">
            <FaMoneyCheckAlt className="text-yellow-500 text-4xl" />
            <div>
              <h3 className="text-lg font-bold text-gray-800">Financial Reports</h3>
              <p className="text-gray-600">Generate and view financial reports.</p>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md flex items-center space-x-4">
            <FaCalendarAlt className="text-red-500 text-4xl" />
            <div>
              <h3 className="text-lg font-bold text-gray-800">Event Calendar</h3>
              <p className="text-gray-600">Manage and view upcoming events.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
