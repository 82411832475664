import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/logo.png';
import { FaHome, FaUsers, FaCoffee, FaSignOutAlt, FaBars } from 'react-icons/fa';

const Header = ({ onLogout }) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="bg-blue-500 text-white px-4 py-2 flex justify-between items-center md:px-8">
      {/* Logo Section */}
      <div className="flex items-center space-x-2">
        <img src={Logo} alt="Logo" className="w-16 h-16 rounded-full" />
        <div className="text-lg md:text-xl font-bold">Account Software</div>
      </div>

      {/* Desktop Menu */}
      <div className="hidden md:flex space-x-4">
        <Link to="/account-statement" className="hover:text-gray-300 flex items-center space-x-1">
          <FaHome />
          <span>Account Statement</span>
        </Link>
        <Link to="/employee-salary" className="hover:text-gray-300 flex items-center space-x-1">
          <FaUsers />
          <span>Employee Salary</span>
        </Link>
        <Link to="/cafe" className="hover:text-gray-300 flex items-center space-x-1">
          <FaCoffee />
          <span>Cafe Sale</span>
        </Link>
        <button
          onClick={onLogout}
          className="bg-red-500 px-4 py-1 rounded hover:bg-red-600 flex items-center space-x-2"
        >
          <span>Logout</span>
          <FaSignOutAlt />
        </button>
      </div>

      {/* Mobile Menu Toggle */}
      <div className="md:hidden">
        <button
          onClick={toggleMobileMenu}
          className="text-white focus:outline-none focus:ring-2 focus:ring-white"
        >
          <FaBars size={24} />
        </button>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="absolute top-20 left-0 w-full bg-blue-500 text-white shadow-lg z-10 md:hidden">
          <Link
            to="/account-statement"
            className="block px-4 py-2 hover:bg-blue-600"
            onClick={() => setMobileMenuOpen(false)}
          >
            <FaHome className="inline mr-2" /> Account Statement
          </Link>
          <Link
            to="/employee-salary"
            className="block px-4 py-2 hover:bg-blue-600"
            onClick={() => setMobileMenuOpen(false)}
          >
            <FaUsers className="inline mr-2" /> Employee Salary
          </Link>
          <Link
            to="/cafe-sale"
            className="block px-4 py-2 hover:bg-blue-600"
            onClick={() => setMobileMenuOpen(false)}
          >
            <FaCoffee className="inline mr-2" /> Cafe Sale
          </Link>
          <button
            onClick={() => {
              onLogout();
              setMobileMenuOpen(false);
            }}
            className="block w-full text-left px-4 py-2 bg-red-500 hover:bg-red-600"
          >
            <FaSignOutAlt className="inline mr-2" /> Logout
          </button>
        </div>
      )}
    </div>
  );
};

export default Header;
