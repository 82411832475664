import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import Header from './components/Header';
import Dashboard from './pages/Dashboard';
import AccountStatement from './pages/AccountStatement';
import BankDetails from "./pages/BankDetails";
import EmployeeSalary from './pages/EmployeeSalary';
import CafeSale from './pages/Cafe';
import CafeDetail from './pages/CafeDetails';
import CafeSales from './pages/CafeSales';
import CafePurchases from './pages/CafePurchases';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('authToken') ? true : false);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <Routes>
        {/* Login Page */}
        <Route path="/" element={!isAuthenticated ? <LoginPage onLogin={handleLogin} /> : <Navigate to="/dashboard" />} />

        {/* Routes with Header */}
        {isAuthenticated && (
          <>
            <Route path="/dashboard" element={<><Header onLogout={handleLogout} /><Dashboard /></>} />
            <Route path="/account-statement" element={<><Header onLogout={handleLogout} /><AccountStatement /></>} />
            <Route path="/account-statement/:bankName" element={<><Header onLogout={handleLogout} /><BankDetails /></>} />
            <Route path="/employee-salary" element={<><Header onLogout={handleLogout} /><EmployeeSalary /></>} />
            <Route path="/cafe" element={<><Header onLogout={handleLogout} /><CafeSale /></>} />
            <Route path="/cafe/:cafeName" element={<><Header onLogout={handleLogout} /><CafeDetail /></>} />
            <Route path="/cafe/:cafeName/sales" element={<><Header onLogout={handleLogout} /><CafeSales /></>} />
            <Route path="/cafe/:cafeName/purchases" element={<><Header onLogout={handleLogout} /><CafePurchases /></>} />
          </>
        )}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;