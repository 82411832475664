import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import { GoDownload } from "react-icons/go";
import AddSaleModal from "../components/Cafes/modal/AddSaleModal";
import { FiSearch } from "react-icons/fi";
import axios from "axios";
import * as XLSX from "xlsx";
import UploadedCsv from "../components/UploadedCvs";

const CafeSales = ({ saleData }) => {
  const location = useLocation();
  const cafeId = location.state?.id;
  const cafeName = location.state?.cafeName;
  const address = location.state?.address;
  const token = localStorage.getItem("authToken");
  const [selectedSale, setSelectedSale] = useState(null);
  const [editSale, setEditSale] = useState(null);

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredSales, setFilteredSales] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [filters, setFilters] = useState({ month: "", date: "" });
  const [itemFilter, setItemFilter] = useState("");
  const [isUploadVisible, setIsUploadVisible] = useState(false);


  const fetchCafeSale = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/sales/${cafeName}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSalesData(response.data.sales);
      setFilteredSales(response.data.sales);
    } catch (error) {
      console.error("Failed to fetch cafe sales:", error);
    }
  };

  useEffect(() => {
    if (cafeName && token) {
      fetchCafeSale();
    }
  }, [cafeName, token,salesData]);

  const upiTotal = filteredSales.reduce((sum, row) => {
    const onlineValue =
      typeof row.online === "string" ? row.online : String(row.online || "0");
    return sum + parseInt(onlineValue.replace("₹", ""), 10);
  }, 0);

  const cashTotal = filteredSales.reduce((sum, row) => {
    const cashValue =
      typeof row.cash === "string" ? row.cash : String(row.cash || "0");
    return sum + parseInt(cashValue.replace("₹", ""), 10);
  }, 0);

  const grandTotal = filteredSales.reduce((sum, row) => {
    const totalValue =
      typeof row.total === "string" ? row.total : String(row.total || "0");
    return sum + parseInt(totalValue.replace("₹", ""), 10);
  }, 0);

  const goBackPage = () => {
    navigate(`/cafe/${cafeName}`, {
      state: {
        cafeName: cafeName,
        address: address,
      },
    });
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
    applyFilters({ ...filters, [name]: value });
  };
  const handleItemFilterChange = (e) => {
    const value = e.target.value;
    setItemFilter(value);
    applyFilters({ ...filters, item: value });
  };

  const resetFilters = () => {
    setFilters({ month: "", date: "" });
    setItemFilter("");
    setFilteredSales(salesData);
  };

  const applyFilters = (updatedFilters) => {
    let filtered = salesData;

    if (updatedFilters.month) {
      filtered = filtered.filter((sale) => {
        const saleMonth = new Date(sale.date).toISOString().slice(0, 7); // Extract "YYYY-MM"
        return saleMonth === updatedFilters.month;
      });
    }

    if (updatedFilters.date) {
      filtered = filtered.filter((sale) => {
        const saleDate = new Date(sale.date).toISOString().split("T")[0]; // Convert to "YYYY-MM-DD"
        return saleDate === updatedFilters.date;
      });
    }

    if (updatedFilters.item) {
      filtered = filtered.filter((sale) =>
        sale.item.toLowerCase().includes(updatedFilters.item.toLowerCase())
      );
    }

    setFilteredSales(filtered);
  };
  const handleEdit = (sale) => {
    setEditSale(sale); // Set the selected sale for editing
    setIsModalOpen(true); // Open the modal
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredSales);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "CafeSales");
    XLSX.writeFile(wb, `${cafeName}_CafeSales.xlsx`);
  };
  const handleSubmit = async (saleData) => {
    try {
      if (editSale) {
        const response = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/api/sales/${cafeName}/${editSale._id}`,
          saleData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const updatedSale = response.data.sale;

        // Update both salesData and filteredSales
        setSalesData((prev) =>
          prev.map((sale) => (sale._id === editSale._id ? updatedSale : sale))
        );
        setFilteredSales((prev) =>
          prev.map((sale) => (sale._id === editSale._id ? updatedSale : sale))
        );

      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/sales/${cafeName}`,
          saleData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const newSale = response.data.sale;

        // Update both salesData and filteredSales
        setSalesData((prev) => [...prev, newSale]);
        setFilteredSales((prev) => [...prev, newSale]);
      }

      setIsModalOpen(false);
      setEditSale(null);
    } catch (error) {
      console.error(
        "Error submitting sale:",
        error.response?.data || error.message
      );
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-white-50 to-white-200 p-6">
      <div className="mb-6">
        {/* Back and Upload CSV Section */}
        <div className="flex items-center justify-between mb-4">
          {/* Back Button */}
          <button
            className="flex items-center text-blue-700 font-medium text-lg hover:text-blue-900 transition"
            onClick={goBackPage}
          >
            <IoIosArrowBack className="text-2xl mr-2" />
            Back
          </button>

          {/* Upload CSV Button */}
          <button
            className="bg-green-600 text-white px-4 py-2 rounded-md flex items-center gap-2 w-full sm:w-auto"
            onClick={() => setIsUploadVisible(!isUploadVisible)}
          >
            Upload CSV
          </button>
        </div>

        {/* Conditionally render the UploadCSV component */}
        {isUploadVisible && <UploadedCsv cafeName={cafeName} />}

        {/* Filters and Actions */}
        <div className="flex flex-wrap items-center justify-between gap-4">
          {/* Month Filter */}
          <div className="flex flex-col sm:flex-row items-center gap-2 w-full sm:w-auto">
            <label className="hidden sm:block text-sm font-medium text-gray-600">
              Month
            </label>
            <input
              type="month"
              name="month"
              className="border rounded px-3 py-2 w-full sm:w-auto"
              onChange={handleFilterChange}
              value={filters.month}
            />
          </div>

          {/* Date Filter */}
          <div className="flex flex-col sm:flex-row items-center gap-2 w-full sm:w-auto">
            <label className="hidden sm:block text-sm font-medium text-gray-600">
              Date
            </label>
            <input
              type="date"
              name="date"
              className="border rounded px-3 py-2 w-full sm:w-auto"
              onChange={handleFilterChange}
              value={filters.date}
            />
          </div>

          {/* Item Filter */}
          <div className="relative flex items-center gap-2 w-full sm:w-auto">
            <input
              type="text"
              placeholder="Filter by item"
              value={itemFilter}
              onChange={handleItemFilterChange}
              className="w-full sm:w-64 px-4 py-2 pl-10 border border-gray-300 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            />
            <FiSearch className="absolute left-3 top-2/4 transform -translate-y-1/2 text-gray-500" />
          </div>

          {/* Reset Filters Button */}
          <button
            className="w-full sm:w-auto bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
            onClick={resetFilters}
          >
            Reset
          </button>

          {/* Export to Excel Button */}
          <button
            className="flex items-center justify-center gap-2 px-4 py-2 bg-blue-100 rounded hover:bg-blue-200 w-full sm:w-auto"
            onClick={exportToExcel}
          >
            <GoDownload className="text-xl text-blue-500" />
            Download
          </button>

          {/* Add Sale Button */}
          <button
            className="bg-blue-600 text-white px-4 py-2 rounded-md flex items-center gap-2 w-full sm:w-auto"
            onClick={() => {
              setEditSale(null); // Reset editSale for adding new sale
              setIsModalOpen(true);
            }}
          >
            <IoMdAdd className="text-xl" />
            Add Sale
          </button>
        </div>

        {/* Modal */}
        {isModalOpen && (
          <AddSaleModal
            editData={editSale} // Pass editSale as editData
            onClose={() => setIsModalOpen(false)}
            onSave={handleSubmit}
          />
        )}
      </div>







      <div className="overflow-x-auto w-full items-center max-w-6xl mx-auto">
        <h1 className="text-4xl font-bold text-gray-800 mb-10 text-center">
          {cafeName}
        </h1>
        <table className="table-auto border-collapse border border-gray-300 w-full text-center bg-white shadow-lg">
          <thead className="bg-blue-500 text-white">
            <tr>
              <th className="border border-gray-300 px-6 py-4">S.No</th>
              <th className="border border-gray-300 px-6 py-4">Date</th>
              <th className="border border-gray-300 px-6 py-4">Item</th>
              <th className="border border-gray-300 px-6 py-4">Quantity</th>
              <th className="border border-gray-300 px-6 py-4">Price</th>
              <th className="border border-gray-300 px-6 py-4">Online</th>
              <th className="border border-gray-300 px-6 py-4">Cash</th>
              <th className="border border-gray-300 px-6 py-4">Total</th>
              <th className="border border-gray-300 px-6 py-4">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredSales.map((sale, index) => (
              <tr
                key={sale.id}
                className={index % 2 === 0 ? "bg-gray-50" : "bg-blue-50"}
              >
                <td className="border px-6 py-4">{index + 1}</td>
                <td className="border border-gray-300 px-6 py-4">
                  {new Date(sale.date).toLocaleDateString("en-GB")}
                </td>
                <td className="border px-6 py-4">{sale.item}</td>
                <td className="border px-6 py-4">{sale.quantity}</td>
                <td className="border px-6 py-4">{sale.price}</td>
                <td className="border px-6 py-4">{sale.online}</td>
                <td className="border px-6 py-4">{sale.cash}</td>
                <td className="border px-6 py-4">{sale.total}</td>
                <td className="border px-6 py-4">
                  <FaRegEdit
                    className="text-blue-500 text-xl cursor-pointer"
                    onClick={() => handleEdit(sale)}
                  />
                </td>
              </tr>
            ))}
            <tr className="bg-yellow-100">
              <td colSpan="5" className="font-bold text-right px-6 py-4">
                Total:
              </td>
              <td className="font-bold px-6 py-4">{`₹${upiTotal}`}</td>
              <td className="font-bold px-6 py-4">{`₹${cashTotal}`}</td>
              <td className="font-bold px-6 py-4">{`₹${grandTotal}`}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CafeSales;