import React from "react";
import EmployeeSalaries from "../components/EmployeeSalary/EmployeeSalaries";

const EmployeeSalary = () => {
 return (
    <>
       <div>
        <EmployeeSalaries/>
       </div>
    </>
  );
};

export default EmployeeSalary;
