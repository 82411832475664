import React, { useState, useEffect } from "react";
import axios from "axios";
import { FiEdit, FiSearch } from "react-icons/fi";
import AddEditEmployeeModal from "./AddEmployeeModal";

const EmployeeSalaries = () => {
  const [employees, setEmployees] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [searchEmp, setSearchEmp] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [newEmployee, setNewEmployee] = useState({
    name: "",
    position: "",
    monthlySalary: "",
    advanceSalary: "",
    description: "",
    date: "",
  });
  const pageNumber = 10;
  const [currentPages, setCurrentPages] = useState(1);

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/employee`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      setEmployees(response.data);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEmployee((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      if (isEditMode) {
        await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/api/employee/${currentEmployee._id}`,
          newEmployee,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );
        setEmployees((prevEmployees) =>
          prevEmployees.map((emp) =>
            emp._id === currentEmployee._id ? { ...newEmployee, _id: currentEmployee._id } : emp
          )
        );
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/employee`,
          newEmployee,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );
        setEmployees((prevEmployees) => [
          ...prevEmployees,
          response.data,
        ]);
      }

      setIsModalOpen(false);
      setIsEditMode(false);
      setNewEmployee({
        name: "",
        position: "",
        monthlySalary: "",
        advanceSalary: "",
        description: "",
        date: "",
      });
    } catch (error) {
      console.error("Error saving employee:", error);
    }
  };

  const handleEdit = (employee) => {
    setIsEditMode(true);
    setCurrentEmployee(employee); 
    setNewEmployee(employee);
    setIsModalOpen(true);
  };
  

  const filteredEmployeeByDate = employees.filter((employee) => {
    const matchesName = employee.name.toLowerCase().includes(searchEmp.toLowerCase());
    const employeeDate = new Date(employee.date);
    const isWithinDateRange =
      (!fromDate || employeeDate >= new Date(fromDate)) &&
      (!toDate || employeeDate <= new Date(toDate));
    return matchesName && isWithinDateRange;
  });

  const indexOfLastItem = currentPages * pageNumber;
  const indexOfFirstItem = indexOfLastItem - pageNumber;
  const currentEmployees = filteredEmployeeByDate.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredEmployeeByDate.length / pageNumber);

  const handlePageChange = (page) => {
    setCurrentPages(page);
  };

  return (
    <div className="p-8">
      {/* Header */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 gap-4">
        <h1 className="text-3xl font-bold text-gray-800">Employee Salaries</h1>
        <div className="flex items-center gap-2">
          <input
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            className="border border-gray-300 rounded-md p-2 text-sm sm:text-base shadow-md"
          />
          <span className="text-gray-500">to</span>
          <input
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            className="border border-gray-300 rounded-md p-2 text-sm sm:text-base shadow-md"
          />
          <div className="relative">
            <input
              type="text"
              placeholder="Search by name..."
              value={searchEmp}
              onChange={(e) => setSearchEmp(e.target.value)}
              className="w-64 px-4 py-2 pl-10 border border-gray-300 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            />
            <FiSearch className="absolute left-3 top-2/4 transform -translate-y-1/2 text-gray-500" />
          </div>
        </div>
        <button
          onClick={() => {
            setIsModalOpen(true);
            setIsEditMode(false);
            setNewEmployee({
              name: "",
              position: "",
              monthlySalary: "",
              advanceSalary: "",
              description: "",
              date: "",
            });
          }}
          className="bg-blue-600 hover:bg-blue-700 text-white px-5 py-2 rounded-md shadow-lg transition duration-300"
        >
          + Add Employee
        </button>
      </div>

      {/* Employee Table */}
      <div className="overflow-x-auto shadow-lg rounded-lg">
        <table className="w-full border border-gray-300 text-left bg-white rounded-md">
          <thead className="bg-blue-500 text-white">
            <tr>
              <th className="px-4 py-2 border">S. No.</th>
              <th className="px-4 py-2 border">Name</th>
              <th className="px-4 py-2 border">Position</th>
              <th className="px-4 py-2 border">Monthly Salary</th>
              <th className="px-4 py-2 border">Advance Salary</th>
              <th className="px-4 py-2 border">Description</th>
              <th className="px-4 py-2 border">Date</th>
              <th className="px-4 py-2 border">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-700">
            {currentEmployees.map((employee, index) => (
              <tr key={employee.id} className="even:bg-gray-50 hover:bg-gray-100">
                <td className="px-4 py-2 border">{index + 1}</td> {/* S. No. */}
                <td className="px-4 py-2 border">{employee.name}</td>
                <td className="px-4 py-2 border">{employee.position}</td>
                <td className="px-4 py-2 border">{employee.monthlySalary}</td>
                <td className="px-4 py-2 border">{employee.advanceSalary}</td>
                <td className="px-4 py-2 border">{employee.description}</td>
                <td className="px-4 py-2 border">
                {new Date(employee.date).toLocaleDateString("en-GB")}
                </td>
                <td className="px-4 py-3 border flex justify-center items-center cursor-pointer text-blue-500 hover:text-blue-700">
                  <FiEdit onClick={() => handleEdit(employee)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <button
          disabled={currentPages === 1}
          onClick={() => handlePageChange(currentPages - 1)}
          className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 mr-2 transition duration-200"
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i}
            onClick={() => handlePageChange(i + 1)}
            className={`px-4 py-2 mx-1 rounded-md ${
              currentPages === i + 1
                ? "bg-blue-600 text-white"
                : "bg-gray-200 hover:bg-gray-300 text-gray-700"
            }`}
          >
            {i + 1}
          </button>
        ))}
        <button
          disabled={currentPages === totalPages}
          onClick={() => handlePageChange(currentPages + 1)}
          className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition duration-200"
        >
          Next
        </button>
      </div>

      {/* Modal */}
      <AddEditEmployeeModal
        isOpen={isModalOpen}
        isEditMode={isEditMode}
        employeeData={newEmployee}
        onInputChange={handleInputChange}
        onSave={handleSave}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default EmployeeSalaries;